<template>
  <div>
    <v-card v-if="!unpacked" flat color="transparent">
      <v-row>
        <v-col class="d-flex" cols="12" md="2" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">Mes DUE actives</v-card-subtitle>
                <v-card-text v-if="due_datas.active_due > 0" class="text-center pa-5 mt-3">
                  <i class="fa-solid fa-signature fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Vous avez {{ due_datas.active_due }} DUE en activité</p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-signature fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Aucune DUE réalisées</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Nombre de DUE réalisées pour le moment. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="2" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes DUE en cours</v-card-subtitle
                >
                <v-card-text v-if="drafts.length > 0" class="text-center pa-5 mt-3">
                  <i class="fa-solid fa-history fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Vous avez 0 DUE en cours</p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-history fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Aucune DUE en cours</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Nombre de DUE en cours pour le moment. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="2" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes DUE à revoir
                </v-card-subtitle>
                <v-card-text v-if="due_datas.expired_due > 0" class="text-center pa-5 mt-3">
                  <i class="fa-solid fa-eye fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Vous avez {{ due_datas.expired_due }} DUE à revoir</p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-history fa-3x EoleBlue--text"></i>
                  <p class="mt-5">Aucune DUE en cours</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Nombre de DUE en cours pour le moment. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="6" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">
                  Représentation des DUE signées</v-card-subtitle
                >
                <v-card-text v-if="drafts.length > 0" class="text-center pa-5 mt-n5">
                  <v-card-text v-if="drafts.length > 0" class="text-center pa-5 mt-n5">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-card-text>
                          <p>Santé</p>
                          <i class="fa-solid fa-heart fa-3x EoleBlue--text"></i>
                          <h1 class="EoleBlue--text">{{ santePercent }}%</h1>
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-card-text>
                          <p>Prévoyance</p>
                          <i class="fa-solid fa-umbrella fa-3x EoleBlue--text"></i>

                          <h1 class="EoleBlue--text">{{ prevoyancePercent }}%</h1>
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-card-text>
                          <p>Retraite</p>
                          <i class="fa-solid fa-wallet fa-3x EoleBlue--text"></i>

                          <h1 class="">{{ retraitePercent }}%</h1>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-percent fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Aucune statistiques</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Nombre de DUE en cours pour le moment. </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip v-if="unlimited === 0" color="EoleBlue">
            <v-icon left size="16">fa-coins</v-icon>

            Jeton(s) restant(s) : {{ tokens }}</v-chip
          >
          <v-chip v-else color="EoleBlue"> Abonné jusqu'au {{ date_resubscription }}</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="text-left">
          <v-tabs
            color="EoleYellow"
            class="text-left mt-6"
            v-model="tab"
            style="box-shadow: none !important"
            background-color="transparent"
            :show-arrows="false"
          >
            <v-tab href="#tab-1" class="mt-2"> Mes clients </v-tab>

            <v-tab href="#tab-2" class="mt-2" :disabled="expired_due_items.length === 0">
              <v-badge
                v-if="expired_due_items.length > 0"
                :content="expired_due_items.length"
                color="EoleErrorBase"
                overlap
                offset-x="0"
                offset-y="0"
              >
                DUE à revoir
              </v-badge>
              <span v-else> DUE à revoir </span>
            </v-tab>

            <v-tab href="#tab-3" class="mt-2"> Historique des actions </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="2" class="text-right" align="center">
          <v-dialog v-model="dialogSearchCompany" max-width="1200px" :persistent="loadingDUE">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon fab class="mt-2" v-on="on" v-bind="attrs">
                <i class="fa-solid fa-plus"></i>
              </v-btn>
            </template>

            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
              <v-card-text style="background-color: #f1f4f9">
                <v-row>
                  <v-col cols="12" md="3" class="no-padding ml-n2">
                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                      <v-card-title>
                        <v-img
                          :src="require('@/assets/images/logos/anavel_logo.png')"
                          max-height="150px"
                          max-width="120px"
                          alt="logo"
                          contain
                        ></v-img>
                      </v-card-title>
                      <v-card-title class="text-center justify-center muller-capitalized"
                        >Mes Services Assurance</v-card-title
                      >
                      <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                      <v-card-text class="no-padding">
                        <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                        </v-img>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="9" class="no-padding d-flex">
                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="loadingDUE">
                      <v-stepper
                        v-model="stepperAddDraft"
                        flat
                        class="rounded-l full-height"
                        height="100%"
                        style="background-color: transparent !important"
                      >
                        <v-stepper-items>
                          <v-stepper-content step="0" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Fonctionnement de Mes Services DUE</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>
                            <v-card-text class="pa-10">
                              <p>
                                La présente décision unilatérale est conforme aux dispositions légales en vigueur,
                                toutefois elle devra être relue et adaptée par le signataire, le cas échéant, afin de
                                tenir compte des pratiques en vigueur dans l’entreprise, du contrat d’assurance et des
                                spécificités conventionnelles.
                              </p>

                              <p>
                                L’entreprise reste soumise à son devoir de vigilance et la responsabilité de la SELARL
                                NUANCES AVOCATS ne pourra être mise en cause en cas de spécificités propres à
                                l’entreprise et/ou son activité.
                              </p>

                              <p>
                                La SELARL NUANCES AVOCATS se tient à votre disposition pour toute consultations
                                spécifique (prestation supplémentaire). Pour ce faire merci de nous contacter par mail :
                                <a href="mailto:nuanceavocats@nuanceavocats.fr">nuanceavocats@nuanceavocats.fr</a>
                              </p>
                            </v-card-text>
                            <v-card-text class="mx-auto text-center">
                              <div>
                                <p>Découvrir</p>
                                <v-img
                                  @click="window.open('https://www.nuances-avocats.com/')"
                                  class="mx-auto"
                                  width="200"
                                  src="https://www.nuances-avocats.com/img/logo-nuances-avocats.svg"
                                ></v-img>
                              </div>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="1" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Pour quelle entreprise souhaitez-vous rédiger une DUE ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>
                            <v-card-text class="text-center align-center mx-auto mt-10">
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    autofocus
                                    label="Siret"
                                    v-model="siretSearch"
                                    outlined
                                    dense
                                    clearable
                                    placeholder="Entrez le SIRET de l'entreprise"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" class="text-left ml-n5">
                                  <v-btn @click="searchPappers" text>
                                    <v-icon left>mdi-magnify</v-icon>
                                    Rechercher
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row class="mt-n10"> </v-row>
                            </v-card-text>
                            <div class="text-left">
                              <v-card-text class="mt-5">
                                <h1>{{ form.nom_entreprise }}</h1>
                              </v-card-text>
                              <v-card-text class="mt-n5">
                                {{ form.siege.adresse_ligne_1 }}
                              </v-card-text>
                              <v-card-text class="mt-n5">
                                {{ form.siege.code_postal }}, {{ form.siege.ville }}
                              </v-card-text>
                              <v-card-text class="mt-n5">
                                {{ form.siege.code_naf }}
                              </v-card-text>
                            </div>
                            <div v-if="pappersLoaded">
                              <v-card-text class="mt-5">
                                <v-row>
                                  <v-col cols="12" md="5" class="mr-n10 mt-2">
                                    <span class="font-weight-bold"> L'entreprise dispose-t-elle d'un CSE ?</span>
                                  </v-col>
                                  <v-col cols="12" md="3" class="ml-n10 mr-n10 mt-1">
                                    <v-btn
                                      @click="radioCSE = 'yes'"
                                      :color="radioCSE === 'yes' ? 'EoleBlue' : null"
                                      small
                                      :class="radioCSE === 'yes' ? 'white--text' : null"
                                    >
                                      Oui
                                    </v-btn>
                                    <v-btn
                                      @click="radioCSE = 'no'"
                                      :color="radioCSE === 'no' ? 'EoleBlue' : null"
                                      small
                                      :class="radioCSE === 'no' ? 'white--text' : null"
                                    >
                                      Non
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      v-if="radioCSE === 'yes'"
                                      label="Date de constitution"
                                      type="date"
                                      v-model="form.dateCSE"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </div>
                            <v-card-text
                              v-if="
                                (pappersLoaded && radioCSE === 'yes' && form.dateCSE) ||
                                (pappersLoaded && radioCSE === 'no')
                              "
                              class="mt-10"
                            >
                              <v-autocomplete
                                :filter="customFilter"
                                label="Convention collective"
                                v-model="selectedCCN"
                                :items="collectiveAgreements"
                                dense
                                item-text="designation"
                                outlined
                              >
                                <template v-slot:item="data">
                                  {{ data.item.designation }} - {{ data.item.idcc }}
                                </template>
                              </v-autocomplete>
                            </v-card-text>
                          </v-stepper-content>
                          <v-stepper-content step="2" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Pour quel risque ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text style="margin-top: 90px" class="text-center align-center mx-auto">
                              <v-row style="max-width: 90%" align="center" class="mx-auto">
                                <v-col v-for="(item, index) in dueItems" :key="index">
                                  <v-card
                                    @click="
                                      () => {
                                        selectDueItem(item)
                                        stepperAddDraft++
                                      }
                                    "
                                    :disabled="item.disabled"
                                    outlined
                                    width="200"
                                    height="200"
                                    elevation="3"
                                    rounded="100"
                                    @mouseover="item.hovered = true"
                                    @mouseleave="item.hovered = false"
                                    class="align-center d-flex rounded-xl"
                                  >
                                    <v-card-text class="text-center pa-5 mt-5">
                                      <i
                                        :class="[
                                          'fa-solid ',
                                          item.icon,
                                          'fa-5x',
                                          item.hovered || dueChoice === item ? 'EoleYellow--text' : 'EoleBlue--text',
                                        ]"
                                      ></i>
                                      <p class="mt-5">{{ item.name }}</p>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="3" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Pour quel collège ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text style="margin-top: 90px" class="text-center align-center mx-auto">
                              <v-row style="max-width: 80%" align="center" class="mx-auto">
                                <v-col v-for="(item, index) in colleageItems" :key="index">
                                  <v-card
                                    @click="
                                      () => {
                                        selectColleageItem(item)
                                      }
                                    "
                                    outlined
                                    width="200"
                                    height="200"
                                    elevation="3"
                                    rounded="100"
                                    @mouseover="item.hovered = true"
                                    @mouseleave="item.hovered = false"
                                    class="align-center d-flex rounded-xl"
                                  >
                                    <v-card-text class="text-center pa-5 mt-5">
                                      <i
                                        :class="[
                                          'fa-solid ',
                                          item.icon,
                                          'fa-5x',
                                          item.hovered || dueColleageChoice === item
                                            ? 'EoleYellow--text'
                                            : 'EoleBlue--text',
                                        ]"
                                      ></i>
                                      <p class="mt-5">{{ item.name }}</p>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-text
                              v-if="dueColleageChoice != null && dueColleageChoice.id === 1"
                              class="ml-colleage-model mt-10"
                            >
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="1"
                                :key="1"
                                inset
                                label="Personnel relevant de l'article 2.1 de l'ANI du 17 Novembre 2017"
                              >
                              </v-switch>
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="2"
                                :key="2"
                                inset
                                label="Personnel relevant de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017"
                              >
                              </v-switch>
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="3"
                                :key="3"
                                inset
                                label="Personnel relevant de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et convention de branche agréée par l'APEC"
                              >
                              </v-switch>
                            </v-card-text>
                            <v-card-text
                              v-else-if="dueColleageChoice != null && dueColleageChoice.id === 2"
                              class="ml-colleage-model mt-10"
                            >
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="5"
                                :key="5"
                                inset
                                label="Personnel ne relevant pas de l'article 2.1 de l'ANI du 17 Novembre 2017"
                              >
                              </v-switch>
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="6"
                                :key="6"
                                inset
                                label="Personnel ne relevant pas de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017"
                              >
                              </v-switch>
                              <v-switch
                                v-model="selectedDueColleageModel"
                                :value="7"
                                :key="7"
                                inset
                                label="Personnel ne relevant pas de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et convention de branche agréée par l'APEC"
                              >
                              </v-switch>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="4" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Quelle est la structure de cotisation du contrat ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text style="margin-top: 30px" class="text-center align-center mx-auto">
                              <v-row style="max-width: 90%" align="center" class="mx-auto">
                                <v-col v-for="(item, index) in dueCotisation" v-bind:key="item.id">
                                  <v-card
                                    @click="
                                      () => {
                                        dueCotisationChoice = item
                                        stepperAddDraft++
                                      }
                                    "
                                    outlined
                                    width="200"
                                    height="200"
                                    elevation="3"
                                    rounded="100"
                                    @mouseover="item.hovered = true"
                                    @mouseleave="item.hovered = false"
                                    class="align-center d-flex rounded-xl"
                                  >
                                    <v-card-text class="text-center pa-5 mt-5">
                                      <i
                                        :class="[
                                          'fa-solid ',
                                          item.icon,
                                          'fa-5x',
                                          item.hovered || dueChoice === item ? 'EoleYellow--text' : 'EoleBlue--text',
                                        ]"
                                      ></i>
                                      <p class="mt-5">{{ item.name }}</p>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="5" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5"
                                >Répartition de la cotisation :
                                {{ dueCotisationChoice.name }}
                              </span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text class="mt-10 my-15">
                              <v-row style="max-width: 70%" align="center" class="mx-auto">
                                <v-col cols="12" md="6" class="text-right">
                                  <v-btn
                                    @click="setDisplayModeToEuro"
                                    fab
                                    :color="displayMode === 'euro' ? 'EoleBlue' : null"
                                    :class="displayMode === 'euro' ? 'white--text' : null"
                                  >
                                    <i class="fa-solid fa-euro-sign fa-2x"></i>
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-btn
                                    @click="setDisplayModeToPercent"
                                    fab
                                    :color="displayMode === 'percent' ? 'EoleBlue' : null"
                                    :class="displayMode === 'percent' ? 'white--text' : null"
                                  >
                                    <i class="fa-solid fa-percent fa-2x"></i>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row class="text-center" style="font-size: 0.8rem">
                                <v-col cols="12" md="12">
                                  <span v-if="displayMode === 'euro'">
                                    Vous avez choisi de saisir les cotisations en €
                                  </span>
                                  <span v-else> Vous avez choisi de saisir les cotisations en % du PMSS </span>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-text class="text-center align-center mx-auto mt-n8">
                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 1"
                                :headers="headersOne"
                                :items="fakeData"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog :return-value.sync="props.item.part_employer">
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 2"
                                :headers="headersTwo"
                                :items="fakeDataTwo"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog
                                    v-if="participationMandatory || props.item.description === 'Salarié'"
                                    :return-value.sync="props.item.part_employer"
                                  >
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                  <span v-else>{{ props.item.part_employer }}%</span>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 3"
                                :headers="headersThree"
                                :items="fakeDataThree"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog
                                    v-if="participationMandatory || props.item.description === 'Adulte (Salarié)'"
                                    :return-value.sync="props.item.part_employer"
                                  >
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                  <span v-else>{{ props.item.part_employer }}%</span>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 4"
                                :headers="headersFour"
                                :items="fakeDataFour"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog
                                    v-if="participationMandatory || props.item.description === 'Salarié'"
                                    :return-value.sync="props.item.part_employer"
                                  >
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                  <span v-else>{{ props.item.part_employer }}%</span>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 5"
                                :headers="headersFive"
                                :items="fakeDataFive"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog
                                    v-if="participationMandatory || props.item.description === 'Isolé'"
                                    :return-value.sync="props.item.part_employer"
                                  >
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                  <span v-else>{{ props.item.part_employer }}%</span>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-data-table
                                hide-default-footer
                                v-if="dueCotisationChoice.id === 6"
                                :headers="headersSix"
                                :items="fakeDataSix"
                              >
                                <template v-slot:header.total_cotisation="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir dans la case le taux contractuel en % du PMSS</span
                                    >
                                    <span v-else>Saisir dans la case le montant contractuel en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_employer="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % de l'employeur</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:header.part_salary="{ header }">
                                  {{ header.text }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <i
                                        class="fa-solid fa-circle-info fa-2x ml-2"
                                        style="font-size: 1.1rem"
                                        v-on="on"
                                      ></i>
                                    </template>
                                    <span v-if="displayMode === 'percent'"
                                      >Saisir la côte part de participation en % du salarié</span
                                    >
                                    <span v-else>Saisir la côte part de participation en € du PMSS</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.total_cotisation="props">
                                  <v-edit-dialog :return-value.sync="props.item.total_cotisation">
                                    {{
                                      displayMode === "euro"
                                        ? props.item.total_cotisation + "€"
                                        : props.item.total_cotisation + "%"
                                    }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="props.item.total_cotisation"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      >
                                      </v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_employer="props">
                                  <v-edit-dialog :return-value.sync="props.item.part_employer">
                                    {{ props.item.part_employer }}% ({{
                                      displayMode === "euro"
                                        ? convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "€"
                                        : convertEuroToPercent(props.item.total_cotisation, props.item.part_employer) +
                                          "%"
                                    }})
                                    <template v-slot:input>
                                      <v-text-field
                                        @input="updatePartSalary(props.item)"
                                        v-model="props.item.part_employer"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:item.part_salary="props">
                                  {{ props.item.part_salary }}% ({{
                                    displayMode === "euro"
                                      ? convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "€"
                                      : convertEuroToPercent(props.item.total_cotisation, props.item.part_salary) + "%"
                                  }})
                                </template>
                              </v-data-table>

                              <v-row v-if="dueCotisationChoice.id != 1 && dueCotisationChoice.id != 6">
                                <v-col cols="12">
                                  <v-switch
                                    label="Couverture des ayants droits à titre obligatoire"
                                    inset
                                    v-model="participationMandatory"
                                    @change="setMandatoryParticipation(fakeDataSix)"
                                  ></v-switch>
                                </v-col>
                                <v-col cols="12" v-if="participationMandatory" class="mt-n10">
                                  <p>
                                    Attention dans ce cas la participation patronale globale doit au moins être
                                    équivalente à 50% de la cotisation de la famille
                                  </p>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="6" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Souhaitez-vous faire apparaître des dispenses facultatives ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text style="margin-top: 30px" class="mx-auto">
                              <v-switch
                                label="Dispenses facultatives"
                                inset
                                v-model="form.isDispenseFacultative"
                              ></v-switch>

                              <v-row v-if="form.isDispenseFacultative">
                                <v-col cols="12" class="mt-5">
                                  <v-switch
                                    v-model="dispenseFacultativesAnswer.apprentiSalarieInf12Mois"
                                    inset
                                    label="Salarié, apprenti ou contrat de mission d'une durée inférieur à 12 mois (sans aucune condition de justification)"
                                  >
                                  </v-switch>
                                </v-col>
                                <v-col cols="12" class="mt-n10">
                                  <v-switch
                                    v-model="dispenseFacultativesAnswer.apprentiSalarieSup12Mois"
                                    inset
                                    label="Salarié, apprenti ou contrat de mission d'une durée égale ou supérieur à 12 mois (à condition de justifier d'une couverture individuelle)"
                                  >
                                  </v-switch>
                                </v-col>
                                <v-col cols="12" class="mt-n10">
                                  <v-switch
                                    v-model="dispenseFacultativesAnswer.apprentiSalarieTpPartiel"
                                    inset
                                    label="Apprenti et salarié à temps partiel dont la cotisation excède 10% de leur rémunération brute"
                                  >
                                  </v-switch>
                                </v-col>
                                <v-col cols="12" class="mt-n10">
                                  <v-switch
                                    v-model="dispenseFacultativesAnswer.salarieOuAytDroit"
                                    inset
                                    label="Salarié bénéficiaire y compris en tant qu'ayant droit d'une couverture frais de santé (Madelin, SNCF, industrie gazière...)"
                                  >
                                  </v-switch>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="7" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Mise en place ou avenant ?</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>
                            <v-card-text style="margin-top: 30px" class="mx-auto">
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-select
                                    label="Est-ce une mise en place ou un avenant ?"
                                    outlined
                                    dense
                                    item-text="value"
                                    item-value="id"
                                    v-model="form.regime"
                                    :items="[
                                      { id: 1, value: 'Mise en place' },
                                      { id: 2, value: 'Modification DUE existante' },
                                    ]"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-if="form.regime === 1"
                                    :label="`Date de mise en place`"
                                    v-model="form.regimeDate"
                                    type="date"
                                    outlined
                                    dense
                                  ></v-text-field>
                                  <v-text-field
                                    v-else
                                    :label="`Date de modification`"
                                    v-model="form.regimeDate"
                                    type="date"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row v-if="form.regime === 2" class="mt-n7 EoleError--text">
                                <v-col>
                                  Attention veuillez rappeler à votre client le processus et délai de dénonciation des
                                  usages
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" v-if="form.regime === 2 && !!form.regimeDate">
                                  <v-text-field
                                    v-model="form.regimeDate2"
                                    outlined
                                    dense
                                    label="Date de mise en place de la décision unilatérale initiale"
                                    type="date"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row
                                v-if="
                                  (form.regime === 1 && !!form.regimeDate) ||
                                  (form.regime === 2 && !!form.regimeDate && !!form.regimeDate2)
                                "
                                class="mt-n5"
                              >
                                <v-col cols="12" md="6">
                                  <v-switch
                                    label="Souhaitez-vous désigner la compagnie"
                                    inset
                                    v-model="hasCompany"
                                  ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6" v-if="hasCompany" class="mt-4">
                                  <v-select
                                    v-model="selectedInsurer"
                                    outlined
                                    dense
                                    label="Compagnie"
                                    :items="insurers"
                                    item-text="name"
                                    item-value="name"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row v-if="hasCompany && !!selectedInsurer" class="mt-n5">
                                <v-col cols="12" md="12" class="EoleError--text">
                                  Nous vous rappelons que la DUE sera valable au maximum 5 ans si vous désignez
                                  l'assureur
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="8" class="no-padding rounded-pill">
                            <v-card-title>
                              <span class="text-h5">Frais de dossiers</span>
                            </v-card-title>
                            <v-divider class="mx-5 mt-n3"></v-divider>

                            <v-card-text style="margin-top: 30px" class="text-center">
                              <i class="fa-solid fa-file-signature fa-8x EoleBlue--text"></i>
                              <p class="mt-2">Coût du document unitaire : 50€</p>
                            </v-card-text>
                            <v-card-text class="text-center mx-auto mt-n5">
                              <v-row class="text-center mx-auto justify-center align-center">
                                <v-col cols="12" md="12" class="d-flex justify-center align-center">
                                  <v-switch
                                    v-model="hasFormFees"
                                    inset
                                    label="Ajouter frais de dossier"
                                    class="d-flex align-center"
                                  ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6" class="mt-3 d-flex justify-center align-center">
                                  <v-text-field
                                    v-if="hasFormFees"
                                    label="Montant €"
                                    v-model="form.fees"
                                    outlined
                                    dense
                                    type="number"
                                    class="d-flex align-center"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>

                      <v-card-actions class="mt-10">
                        <v-btn text @click="stepperAddDraft--" v-if="stepperAddDraft > 0">Retour</v-btn>
                        <v-spacer></v-spacer>

                        <!-- Bouton Suivant pour les étapes 1 à 6 -->
                        <v-btn
                          v-if="stepperAddDraft !== 2 && stepperAddDraft <= 6"
                          text
                          @click="stepperAddDraft++"
                          :disabled="
                            stepperAddDraft === 2 && dueColleageChoice === null && selectedDueColleageType == null
                          "
                        >
                          Suivant
                        </v-btn>

                        <!-- Bouton Finaliser pour l'étape 7 -->
                        <v-btn
                          v-else-if="stepperAddDraft === 7 && unlimited === 0 && tokens === 0"
                          text
                          @click="stepperAddDraft++"
                        >
                          Finaliser
                        </v-btn>

                        <v-btn
                          v-else-if="stepperAddDraft === 7 && unlimited === 0 && tokens > 0"
                          text
                          @click="generateDUE"
                          :loading="loadingDUE"
                        >
                          Générer
                        </v-btn>

                        <v-btn
                          v-else-if="stepperAddDraft === 7 && unlimited === 1"
                          text
                          @click="generateDUE"
                          :loading="loadingDUE"
                        >
                          Générer
                        </v-btn>

                        <!-- Boutons pour l'étape 8 -->
                        <v-btn
                          v-if="stepperAddDraft === 8 && tokens > 0 && unlimited === 0"
                          text
                          @click="generateDUE"
                          :loading="loadingDUE"
                        >
                          Payer
                        </v-btn>

                        <v-btn
                          v-if="stepperAddDraft === 8 && unlimited === 1"
                          text
                          @click="generateDUE"
                          :loading="loadingDUE"
                        >
                          Générer
                        </v-btn>

                        <v-btn
                          v-if="stepperAddDraft === 8 && unlimited === 0 && tokens === 0"
                          text
                          @click="generateDUE"
                          :loading="loadingDUE"
                        >
                          Payer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab" style="background-color: transparent !important">
        <v-tab-item :value="'tab-1'" class="mb-5">
          <div v-if="drafts.length > 0">
            <v-row class="mt-5">
              <v-col cols="12" md="6">
                <v-text-field v-model="siretFind" dense clearable placeholder="Rechercher un client"> </v-text-field>
              </v-col>
            </v-row>
            <v-card-text class="no-padding">
              <v-list style="z-index: 0" dense>
                <template v-for="(draft, draftIndex) in searchInCustomer">
                  <v-list-item @click="openDraft(draft)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row>
                          <v-col cols="12" md="6" class="mt-6">
                            <span class="font-weight-bold">{{ draft.commercial_name }}</span> ({{ draft.siret }}) -
                            {{ draft.address }}, {{ draft.postal_code }} {{ draft.city }}
                          </v-col>
                          <v-col cols="12" md="6" class="text-right mt-4">
                            <v-tooltip v-for="(i, index) in draft.due_items" :key="index" top>
                              <template v-slot:activator="{ on }">
                                <v-avatar
                                  v-on="on"
                                  :color="i.isGenerate ? i.color : ''"
                                  small
                                  :class="[i.isGenerate ? 'white--text' : '', index !== 0 ? 'mx-2' : null]"
                                  size="32"
                                >
                                  {{ i.sigle }}
                                </v-avatar>
                              </template>
                              <span>{{ i.name }} {{ i.category }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-5"> </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-btn @click="openDraft(draft)" fab icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="draftIndex < drafts.length - 1"></v-divider>
                </template>
              </v-list>
            </v-card-text>
          </div>
          <v-card-text v-else>
            <v-alert type="info" text>
              Aucune DUE réalisée à ce jour vous pouvez en créer une en cliquant sur le bouton "+"
            </v-alert>
          </v-card-text>
        </v-tab-item>
        <v-tab-item :value="'tab-2'" class="mb-5">
          <v-row class="mt-5">
            <v-col cols="12" md="6">
              <v-text-field v-model="siretFindExpired" dense clearable placeholder="Rechercher un client">
              </v-text-field>
            </v-col>
          </v-row>
          <v-card-text class="no-padding">
            <v-list style="z-index: 0" dense>
              <template v-for="(expiredDue, draftIndex) in searchInExpiredDue">
                <v-list-item @click="openDraft(expiredDue)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row>
                        <v-col cols="12" md="6" class="mt-6">
                          <span class="font-weight-bold">{{ expiredDue.commercial_name }}</span> ({{
                            expiredDue.siret
                          }}) - {{ expiredDue.address }}, {{ expiredDue.postal_code }} {{ expiredDue.city }}
                        </v-col>
                        <v-col cols="12" md="6" class="text-right mt-4">
                          <v-chip color="EoleErrorBase"> Expire le {{ expiredDue.expired_date_display }} </v-chip>
                          <!-- <v-tooltip v-for="(i, index) in draft.due_items" :key="index" top>
                          <template v-slot:activator="{ on }">
                            <v-avatar
                              v-on="on"
                              :color="i.isGenerate ? i.color : ''"
                              small
                              :class="[i.isGenerate ? 'white--text' : '', index !== 0 ? 'mx-2' : null]"
                              size="32"
                            >
                              {{ i.sigle }}
                            </v-avatar>
                          </template>
                          <span>{{ i.name }} {{ i.category }}</span>
                        </v-tooltip> -->
                        </v-col>
                      </v-row>
                    </v-list-item-title>

                    <v-list-item-subtitle class="mt-5"> </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>
                      <v-btn @click="openDraft(expiredDue)" fab icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="draftIndex < expiredDue.length - 1"></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-tab-item>
        <v-tab-item :value="'tab-3'" class="mb-5">
          <!-- Section des paiements -->
          <v-card class="my-5">
            <v-card-title>Paiements réalisés</v-card-title>
            <v-list dense>
              <v-list-item-group>
                <v-list-item v-for="payment in packPayment" :key="payment.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ payment.designation }}</v-list-item-title>
                    <v-list-item-subtitle> Montant: {{ payment.price }}€ </v-list-item-subtitle>

                    <v-list-item-subtitle> Status: {{ payment.status }} </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text class="EoleGreen--text">
                      +{{ payment.tokens }} <v-icon right size="16" color="EoleGreen">fa-coins</v-icon>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>

          <!-- Section des actions -->
          <v-card class="mb-4">
            <v-card-title>Actions réalisées</v-card-title>
            <v-list dense>
              <v-list-item-group>
                <v-list-item v-for="action in actions" :key="action.id">
                  <v-list-item-content>
                    <v-list-item-title
                      >Génération DUE pour <span class="font-weight-bold">{{ action.commercial_name }}</span> ({{
                        action.siret
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle> Status: {{ action.status }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text class="EoleError--text">
                      -{{ action.token_use }} <v-icon right size="16" color="EoleError">fa-coins</v-icon>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>

          <!-- Section des packs -->
          <!-- <v-card class="mb-4">
            <v-card-title>Packs</v-card-title>
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="pack in packPayment" :key="pack.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ pack.designation }}</v-list-item-title>
                    <v-list-item-subtitle> Description: {{ pack.description }} </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Prix: {{ pack.price }} - Tokens: {{ pack.tokens }} - Illimité: {{ pack.unlimited }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card> -->
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div v-else>
      <due-no-pack></due-no-pack>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js"

import "@mdi/font/css/materialdesignicons.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
import DueNoPack from "./components/DueNoPack"
import moment from "moment"

export default {
  name: "serviceDue",
  data: () => ({
    packPayment: [],
    actions: [],
    expired_due_items: [],
    date_resubscription: null,
    unlimited: 0,
    tokens: 0,
    participationMandatory: false,
    hasFormFees: false,
    hasCompany: false,
    loadingDUE: false,
    siretFind: "",
    siretFindExpired: "",
    dispenseFacultativesAnswer: {
      apprentiSalarieInf12Mois: false,
      apprentiSalarieSup12Mois: false,
      apprentiSalarieTpPartiel: false,
      salarieOuAytDroit: false,
    },
    radioCSE: null,
    retraitePercent: 0,
    prevoyancePercent: 0,
    santePercent: 0,
    selectedDueItems: [],
    due_items: [
      {
        id: 1,
        name: "Santé",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 1,
        category: "Cadre",
        isGenerate: false,
        sigle: "SC",
        color: "EoleBlue",
        disabled: false,
      },

      {
        id: 2,
        name: "Santé",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 1,
        category: "Non cadre",
        isGenerate: false,
        sigle: "SNC",
        color: "EoleBlue",
        disabled: true,
      },
      {
        id: 3,
        name: "Prévoyance",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 2,
        category: "Cadre",
        isGenerate: false,
        sigle: "PC",
        color: "EoleYellow",
        disabled: true,
      },
      {
        id: 4,
        name: "Prévoyance",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 2,
        category: "Non cadre",
        isGenerate: false,
        sigle: "PNC",
        color: "EoleYellow",
      },
      {
        id: 5,
        name: "Retraite",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 3,
        category: "Cadre",
        isGenerate: false,
        sigle: "RC",
        color: "EoleGray",
      },
      {
        id: 6,
        name: "Retraite",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 3,
        category: "Non cadre",
        isGenerate: false,
        sigle: "RNC",
        color: "EoleGray",
      },
    ],
    selectedCCN: null,
    selectedInsurer: "",
    displayMode: "percent",
    selectedDueColleageType: {
      id: 9,
      name: "",
    },
    selectedDueColleage: null,
    fakeData: [
      {
        description: "Salarié seul",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    fakeDataTwo: [
      {
        description: "Isolé",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "Famille",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    fakeDataThree: [
      {
        description: "Adulte (Salarié)",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "Adulte (Conjoint)",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "Enfant",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    fakeDataFour: [
      {
        description: "Salarié",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "Conjoint",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "Enfant",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    fakeDataFive: [
      {
        description: "Isolé",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "duo",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
      {
        description: "famille",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    fakeDataSix: [
      {
        description: "Famille",
        total_cotisation: "0",
        part_employer: "0",
        part_salary: "0",
      },
    ],
    tab: "tab-1",
    headersOne: [
      {
        text: "Structure de cotisation",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    headersTwo: [
      {
        text: "Isolé / Famille",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    headersThree: [
      {
        text: "Adulte / Enfant",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    headersFour: [
      {
        text: "Salarié / Conjoint / Enfant",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    headersFive: [
      {
        text: "Isolé / Duo / Famille",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    headersSix: [
      {
        text: "Structure de cotisation",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Cotisation totale",
        align: "start",
        sortable: false,
        value: "total_cotisation",
      },
      {
        text: "Part patronale",
        value: "part_employer",
      },
      {
        text: "Part salariale",
        value: "part_salary",
      },
    ],
    dueCotisation: [
      {
        id: 1,
        name: "Salarié seul",
        hovered: false,
        icon: "fa-wallet",
      },
      {
        id: 2,
        name: "Isolé/Famille",
        hovered: false,
        icon: "fa-wallet",
      },
      {
        id: 3,
        name: "Adulte/Enfant",
        hovered: false,
        icon: "fa-wallet",
      },
      {
        id: 4,
        name: "Salarié/Conjoint/Enfant",
        hovered: false,
        icon: "fa-wallet",
      },
      {
        id: 5,
        name: "Isolé/Duo/Famille",
        hovered: false,
        icon: "fa-wallet",
      },
      {
        id: 6,
        name: "Famille",
        hovered: false,
        icon: "fa-wallet",
      },
    ],
    dueCotisationChoice: {
      id: null,
      name: null,
    },
    dueColleageType: null,
    dueColleageChoice: {
      id: null,
      description: null,
    },
    colleageItems: [
      {
        id: 1,
        name: "Cadre",
        hovered: false,
        icon: "fa-user-tie",
      },
      {
        id: 2,
        name: "Non cadre",
        hovered: false,
        icon: "fa-user",
      },
      {
        id: 3,
        name: "Ensemble du personnel",
        hovered: false,
        icon: "fa-users",
      },
    ],
    stepperAddDraft: 0,
    dueChoice: null,
    dueItems: [
      {
        id: 1,
        name: "Santé",
        hovered: false,
        icon: "fa-heart",
        disabled: false,
      },
      {
        id: 2,
        name: "Prévoyance",
        hovered: false,
        icon: "fa-umbrella",
        disabled: true,
      },
      {
        id: 3,
        name: "Retraite",
        hovered: false,
        icon: "fa-wallet",
        disabled: true,
      },
    ],
    currentCompanyName: "",
    collectiveAgreements: [],
    stepper: 0,
    form: {
      nom_entreprise: null,
      siret: "",
      siege: {
        adresse_ligne_1: "",
        code_postal: "",
        ville: "",
        code_naf: "",
      },
      isDispensePublic: false,
      isDispenseFacultative: false,
      regime: "",
      regimeDate: "",
      participationMandatory: false,
    },
    drafts: [],
    dialogSearchCompany: false,
    company: [],
    siretSearch: "",
    pappersLoaded: false,
    unpacked: false,
    due_datas: {
      active_due: 0,
      expired_due: 0,
    },
    hasUnsavedChanges: true,
  }),
  created() {},
  mounted() {
    // if (localStorage.getItem("drafts")) {
    //   this.drafts = JSON.parse(localStorage.getItem("drafts"))
    //   // this.getColorDueItems()

    //   this.calculatePercentByDueItems()
    // }

    this.getInformations()
    this.getAgencyPack()

    this.fetchCustomers()

    this.fetchCollectiveAgreements()
    this.fetchAgencyPartners()
    this.fetchHistoryActions()
  },
  beforeDestroy() {
    // Supprimer l'événement `beforeunload` lorsque le composant est détruit
    window.removeEventListener("beforeunload", this.handleBeforeUnload)
  },
  computed: {
    searchInExpiredDue() {
      return this.expired_due_items.filter(draft => {
        return draft.commercial_name.toLowerCase().includes(this.siretFindExpired.toLowerCase())
      })
    },
    searchInCustomer() {
      return this.drafts.filter(draft => {
        return draft.commercial_name.toLowerCase().includes(this.siretFind.toLowerCase())
      })
    },
    selectedDueColleageModel: {
      get() {
        return this.selectedDueColleageType.id
      },
      set(value) {
        // Trouve la description basée sur l'ID
        const descriptions = {
          1: "Personnel relevant de l'article 2.1 de l'ANI du 17 Novembre 2017",
          2: "Personnel relevant de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017",
          3: "Personnel relevant de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et ...",
          5: "Personnel ne relevant pas de l'article 2.1 de l'ANI du 17 Novembre 2017",
          6: "Personnel ne relevant pas de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017",
          7: "Personnel ne relevant pas de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et ...",
        }
        this.selectedDueColleageType = value
          ? {
              id: value,
              description: descriptions[value],
            }
          : null
      },
    },
  },
  components: {
    DueNoPack,
  },

  methods: {
    async fetchHistoryActions() {
      try {
        const response = await fetch(
          `${config.apiUri}/agencies/${this.$store.state.user.agency_id}/due/actions/history`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          },
        )

        if (response.ok) {
          const data = await response.json()
          this.packPayment = data.packPayment
          this.actions = data.actions
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleInput() {
      this.hasUnsavedChanges = true
    },
    handleBeforeUnload(event) {
      // Définir le message de confirmation
      event.preventDefault()
      event.returnValue = "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?"
    },
    async getInformations() {
      try {
        const response = await fetch(
          `${config.apiUri}/agencies/${this.$store.state.user.agency_id}/customers/due/informations`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          },
        )

        if (response.ok) {
          const data = await response.json()

          this.santePercent = data.category_percentages.Santé == null ? 0 : data.category_percentages.Santé
          this.prevoyancePercent =
            data.category_percentages.Prévoyance == null ? 0 : data.category_percentages.Prévoyance
          this.retraitePercent = data.category_percentages.Retraite == null ? 0 : data.category_percentages.Retraite

          this.due_datas = {
            active_due: data.active_due,
            expired_due: data.expired_due,
          }

          this.expired_due_items = data.expired_due_items

          this.affectDisplayExpiredDate(this.expired_due_items)
        }
      } catch (error) {
        console.log(error)
      }
    },

    affectDisplayExpiredDate(expiredDues) {
      expiredDues.forEach(expiredDue => {
        expiredDue.expired_date_display = moment(expiredDue.expired_at).format("DD/MM/YYYY")
      })
    },
    async getAgencyPack() {
      try {
        const response = await fetch(`${config.apiUri}/agencies/${this.$store.state.user.agency_id}/due/packs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.status === 202) {
          this.unpacked = true
        } else if (response.status === 200) {
          const data = await response.json()
          this.unlimited = data.unlimited
          this.tokens = data.token

          if (data.unlimited === 1) {
            this.date_resubscription = moment(data.date_next_shop).format("DD/MM/YYYY")
          }
        } else {
          console.log("Erreur lors de la récupération des packs")
        }
      } catch (error) {
        console.log(error)
      }
    },
    customFilter(item, queryText, itemText) {
      console.log(item)
      const idccString = item.idcc.toString()
      const text = item.designation.toLowerCase() + " " + idccString
      const searchText = queryText.toLowerCase()
      return text.includes(searchText)
    },
    async fetchCustomers() {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/customers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          const data = await response.json()

          this.drafts = data.map(customer => {
            // Copie du tableau due_items et mise à jour de l'état de génération pour chaque customer
            const updatedDueItems = this.due_items.map(item => {
              const generatedItem = customer.due_items.find(
                apiItem =>
                  apiItem.id_customer_professionnal_due_category === item.id_customer_professionnal_due_category &&
                  apiItem.id_customer_professionnal_due_type === item.id_customer_professionnal_due_type,
              )
              return {
                ...item,
                isGenerate: !!generatedItem,
              }
            })

            return {
              ...customer,
              due_items: updatedDueItems,
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async generateDUE() {
      this.loadingDUE = true
      let array = []
      let headers = []
      switch (this.dueCotisationChoice.id) {
        case 1:
          array = this.fakeData
          headers = this.headersOne
          break
        case 2:
          array = this.fakeDataTwo
          headers = this.headersTwo
          break
        case 3:
          array = this.fakeDataThree
          headers = this.headersThree
          break
        case 4:
          array = this.fakeDataFour
          headers = this.headersFour
          break
        case 5:
          array = this.fakeDataFive
          headers = this.headersFive
          break
        case 6:
          array = this.fakeDataSix
          headers = this.headersSix
          break
      }

      array = array.map(item => {
        return {
          description: item.description,
          total_cotisation: this.displayMode === "euro" ? item.total_cotisation + "€" : item.total_cotisation + "%",
          part_employer: this.displayMode === "euro" ? item.part_employer + "€" : item.part_employer + "%",
          part_salary: this.displayMode === "euro" ? item.part_salary + "€" : item.part_salary + "%",
        }
      })

      try {
        const response = await fetch(`${config.apiUri}/due/create-payment-session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            radioCSE: this.radioCSE,
            compagnie: this.selectedInsurer,
            form: this.form,
            courtier: this.$store.state.user.agency_name,
            college: this.dueColleageChoice.id,
            article: this.selectedDueColleageType.id,
            tableau_repartition: array,
            entete_tableau_repartition: headers,
            dispenseOrdrePublic: this.form.isDispensePublic,
            dispenseFacultative: this.form.isDispenseFacultative,
            dispensesReponses: this.dispenseFacultativesAnswer,
            id_agency: this.$store.state.user.agency_id,
            designe_assureur: this.hasCompany,
            ayant_droit: this.participationMandatory,
            type: this.form.regime,
          }),
        })

        if (response.ok) {
          const data = await response.json()

          if (data.redirect_url) {
            // Redirection si le document a été généré sans paiement
            window.location.href = data.redirect_url
          } else {
            const { sessionId } = data

            // Redirection vers Stripe
            const stripe = await loadStripe(
              "pk_test_51P3JIlDQMkagpLizvGVoKxpk2tEaNrZ6c3tSn9ehhmGKQjj6q5M0ILEIbS0dKU3XzGP6IvXTLvaIslJI26de9Xyd00D7qWwQsm",
            )
            const { error } = await stripe.redirectToCheckout({ sessionId })
            if (error) {
              console.error("Error redirecting to Stripe:", error)
            }
          }
        } else {
          const data = await response.text()
          this.$toast.error(`Erreur lors de la création de la session de paiement: ${data}`, {
            duration: 5000,
            position: "bottom-right",
          })
        }
      } catch (error) {
        console.log(error)
        this.$toast.error(`Erreur lors de la création de la session de paiement: ${error}`, {
          duration: 5000,
        })
      }

      this.loadingDUE = false
    },

    calculatePercentByDueItems() {
      let nombreDueSanteRealise = 0
      let nombreDuePrevoyanceRealise = 0
      let nombreDueRetraiteRealise = 0

      this.drafts.forEach(draft => {
        draft.due_items.forEach(dueItem => {
          if (dueItem.isGenerate) {
            if (dueItem.name === "Santé") {
              nombreDueSanteRealise++
            }

            if (dueItem.name === "Prévoyance") {
              nombreDuePrevoyanceRealise++
            }

            if (dueItem.name === "Retraite") {
              nombreDueRetraiteRealise++
            }
          }
        })
      })

      console.log(nombreDueSanteRealise, nombreDuePrevoyanceRealise, nombreDueRetraiteRealise)

      this.retraitePercent = ((nombreDueRetraiteRealise / this.drafts.length) * 100).toFixed(2)
      this.prevoyancePercent = ((nombreDuePrevoyanceRealise / this.drafts.length) * 100).toFixed(2)
      this.santePercent = ((nombreDueSanteRealise / this.drafts.length) * 100).toFixed(2)
    },

    selectDueItem(item) {
      this.dueChoice = item
      this.addSelectedDueItem()
    },
    selectColleageItem(item) {
      this.dueColleageChoice = item
      this.addSelectedDueItem()

      console.log(this.selectedDueItems)
    },
    addSelectedDueItem() {
      if (this.dueChoice && this.dueColleageChoice) {
        const selectedItem = this.due_items.find(
          dueItem => dueItem.name === this.dueChoice.name && dueItem.category === this.dueColleageChoice.name,
        )
        if (selectedItem) {
          this.selectedDueItems = selectedItem
        }
      }
    },
    saveNewDue() {
      const existingItem = this.due_items.find(
        dueItem => dueItem.name === this.selectedDueItems.name && dueItem.category === this.selectedDueItems.category,
      )
      if (existingItem) {
        existingItem.isGenerate = true
      }

      this.form.id = this.form.siege.siret
      this.form.due_items = this.due_items

      this.drafts.push(this.form)

      localStorage.setItem("drafts", JSON.stringify(this.drafts))

      this.calculatePercentByDueItems()
    },
    sortDueItems(dueItems) {
      return dueItems.sort((a, b) => a.id - b.id)
    },

    updateSelectedDueColleage(id, description) {
      this.selectedDueColleage = {
        id,
        description,
      }
    },
    async fetchAgencyPartners() {
      try {
        const response = await fetch(`${config.apiUri}/agencies/${this.$store.state.user.agency_id}/partners`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()
        this.insurers = data
      } catch (error) {
        console.log(error)
      }
    },
    setMandatoryParticipation(item) {
      if (item.participationMandatory) {
        item.part_employer = 50
        item.part_salary = 50
      }
    },
    updatePartitions(item) {
      const total = parseFloat(item.total_cotisation) // Assurez-vous que c'est un nombre

      // item.part_employer = (total * parseFloat(item.part_employer)) / 100
      // item.part_salary = total - item.part_employer
    },
    convertEuroToPercent(amount, reference) {
      return (amount * reference) / 100
    },
    setDisplayModeToEuro() {
      this.displayMode = "euro"
    },
    setDisplayModeToPercent() {
      this.displayMode = "percent"
    },
    async checkoutPayment() {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/payments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
            "access-control-allow-origin": "https://localhost:8080",
          },
          body: JSON.stringify({
            price: 10,
            currency: "eur",
          }),
        })

        const data = await response.json()

        window.location.href = data.url
      } catch (error) {
        console.log(error)
      }
    },
    updatePartSalary(item) {
      // Assurez-vous que part_employer est un nombre.
      const partEmployer = parseFloat(item.part_employer)
      if (!isNaN(partEmployer) && partEmployer >= 0 && partEmployer <= 100) {
        // Ajustez part_salary pour qu'elle soit le complément à 100 de part_employer.
        item.part_salary = `${100 - partEmployer}`
      }
    },
    changeMoney() {},
    openDraft(draft) {
      this.$router.push({
        name: "itemServiceDue",
        params: {
          id: draft.siret.toString(),
        },
      })
    },
    initializeDraft() {
      let index = this.form.siege.siret
      this.form.id = index
      this.form.due_items = this.due_items
    },
    async searchPappers() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/pappers/${this.siretSearch}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        let data = await response.json()

        if (data.hasOwnProperty("error")) {
          this.errorSiretMessage = `Nous n'avons pas trouvé d'entreprise avec le numéro de siret`
        } else {
          if (response.ok) {
            // this.company = data
            this.form = data
            this.form.title = data.nom_entreprise

            this.pappersLoaded = true
            // this.dialogSearchCompany = false

            this.initializeDraft()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCollectiveAgreements() {
      try {
        const response = await fetch(`${config.apiUri}/collective-agreements`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()
        this.collectiveAgreements = data

        console.log(this.collectiveAgreements)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style>
.ml-colleage-model {
  margin-left: 100px !important;
  width: 80% !important;
}
.mullerFont {
  font-family: muller;
  font-size: 1.4rem;
  font-weight: 200;
}

.div-block {
  display: inline-block;

  /* set left and right space */
  margin-right: 10px;
  margin-left: 10px;
}

.v-text-field {
  text-align: center;
  margin-right: 10px;
  line-height: 1.5rem;
  padding: 0.375rem 0.875rem;
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -57px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}
</style>
